import UserDTO, { IUser } from './UserDTO'
import Com from '../services/ComService'
export default class UsersDTO {
  list: UserDTO[] = []
  public async getFromServer(force?: boolean) {
    if (this.list.length === 0 || force) {      
      const users = await Com('users') as IUser[]
      this.list = users.map(u => new UserDTO(u))
    }
  }
  public async newUser(d: {email: string, password: string}) {
    const result = await Com('PUT:admin/user', {
      email: d.email,
      wurzel: d.password
    }, 1) as {status: string, response: IUser}
    console.log('new user', result)
    if (result.status === 'okay') {
      this.list.push(new UserDTO(result.response as IUser))
    }
    return result.status
  }
  public async deleteUser(d: {id: number}) {
    const user = this.list.find(u => u.id === d.id)
    if (!user) { return }
    await user.delete()
    this.list = this.list.filter(u => u.id != d.id)
  }
  public getList() {
    return this.list
  }
}
