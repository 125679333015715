import Com from "../services/ComService";
import userService from "../services/UserService";
import GroupDTO, { IGroup } from "./GroupDTO";

export default class GroupsDTO {
  list: GroupDTO[] = []
  public async getFromServer(force?: boolean) {
    const user = await userService.get()
    if (this.list.length === 0 || force) {      
      const list = await Com('ugroups') as IGroup[]
      this.list = list.map(g => new GroupDTO(g, {
        userId: user?.id || -1
      }))
    }
  }
  public async newGroup(d: {
    name: string,
  }) {
    const group = await Com('PUT:admin/ugroup', {
      name: d.name
    })
    this.list.push(new GroupDTO(group as IGroup))
  }
  public async deleteGroup(d: {id: number}) {
    const group = this.list.find(u => u.id === d.id)
    if (!group) { return }
    // await group.delete()
    // only mark as deleted
    await group.saveProp({
      id: -1,
      key1: 'groupconf',
      key2: 'deleted',
      value1: new Date().getTime() + '',
      value2: new Date().toJSON(),
    })
    // this.list = this.list.filter(u => u.id != d.id)
  }
  public getById(id: number) {
    return this.list.find(g => g.id === id)
  }
  public getList() {
    return this.list.filter(g => !g.hasProp({key1: 'groupconf', key2: 'deleted'}))
  }
}