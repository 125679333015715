import config from '../config.json'
import axios from 'axios'
import loginService from './LoginService'

const commandRegex = '([a-zA-Z]+):(.*)'
/**
 * 
 * @param command string of structure KIND:actionpath aka GET:user/login
 */
export default async function Com(command: string, body?: any, level?: number) {
  let method = 'get'
  let path = command + ''
  const r = command.match(commandRegex)
  if (r) {
    method = r[1]
    path = r[2]
  }
  return new Promise((success, reject) => {
    axios({
      method: method,
      url: config.apiPrefix + path,
      data: body
    }).then((result) => {
      switch(level) {
        case 0:
          success(result)
          return
        case 1:
          success(result.data)
          return
        case 2:
        default:
          success(result.data.response)
          return
      }
    }).catch(async (err) => {
      // Test if we are logged in
      if (await !loginService.IsLoggedIn()) {
        loginService.Logout()
      }
      // If error because of not authorizes: move to start-page

      // loginService.Logout()
      reject(err)
    })
  })
}
