import { useEffect, useState } from "react";
import { Plus } from "react-feather";
import AppointmentRuleDTO from "../../dto/AppointmentRuleDTO";
import AppointmentRulesDTO from "../../dto/AppointmentRulesDTO";
import i18n from "../../services/i18n";
import Spinner from "../Spinner/Spinner";
import AppointmentRule from "./AppointmentRule";

enum Status {
  loading,
  done,
  add,
}
export default function AppointmentRules(props: {
  UGroupId: number
}) {
  const [rules] = useState(new AppointmentRulesDTO({
    UGroupId: props.UGroupId
  }))
  const [status, setStatus] = useState(Status.loading)
  const [Rerender, setRerender] = useState(0)
  const [newRule] = useState(new AppointmentRuleDTO({
    UGroupId: props.UGroupId
  }))
  useEffect(() => {
    rules.getFromServer().then(() => {
      // setRerender(Rerender + 1)
      setStatus(Status.done)
    })

  }, [])
  // (appointmentRule: AppointmentRuleDTO)
  function reload() {
    console.log('Add done!')
    setStatus(Status.loading)
    rules.getFromServer().then(() => {
      console.log('new content loaded')
      // setRerender(Rerender + 1)
      setStatus(Status.done)
    })
  }
  return <div
    className='w3-container'
  >
    <h3>{i18n.get('AppointmentRules')}</h3>
    {
      status === Status.loading &&
      <Spinner />
    }
    {
      status === Status.done &&
      <button
        className='w3-button w3-padding w3-margin w3-ripple w3-green'
        onClick={() => {
          setStatus(Status.add)
        }}
      ><Plus /></button>
    }
    {
      status === Status.add &&
      <AppointmentRule
        AppointmentRule={newRule}
        addMode={true}
        cb={reload}
      />
    }
    {
      status !== Status.loading &&
      <div>
        {
          rules.rules.map(rule => <AppointmentRule
              key={`AR-${rule.id}`}
              AppointmentRule={rule}
              cb={reload}
            />)
        }
      </div>
    }
  </div>
}
