import Com from "../services/ComService"
import { dateCeilHour, dateFloorHour, printShortDate, printUserTime, printWeekday } from "../services/DateService"
// import i18n from "../services/i18n"


type Prop = {
  id: number,
  key1: string,
  key2: string,
  value1: string,
  value2: string,
}

type AppointmentProp = {
  key1: string,
  value1: string,
}

type Appointment = {
  id: number,
  start: Date,
  end: Date,
  props: AppointmentProp[],
}

const minHeightWithoutSlotGroup = 8

type slotGroup = {
  id: number,
  start: Date,
  end: Date,
  count: number,
  top: string,
  height: string,
}

class slotGroupManager {
  slotGroups: slotGroup[] = []
  // groupCounter: number = 0
  // true if slot is in group
  reset() {
    this.slotGroups = []
  }
  addGroupBySlot(slot: Slot): number {
    if (!slot.heightInt || slot.heightInt > minHeightWithoutSlotGroup) {
      return -1
    }
    const g: slotGroup | undefined  = this.slotGroups.find(sG => sG.start <= slot.start && slot.start < sG.end)
    if (g) {
      g.count += 1
      return g.id
    }
    const start = dateFloorHour(slot.start)
    const end = dateCeilHour(slot.end)
    // this.groupCounter += 1
    this.slotGroups.push({
      id: start.getTime(),
      start: start,
      end: end,
      top: '',
      height: '',
      count: 1,
    })
    return start.getTime()
  }
  slotIsInGroup(slot: Slot) {
    return this.slotGroups.some(sG => sG.start <= slot.start && slot.start < sG.end)
  }
  getGroups(userDate: string) {
    return this.slotGroups.filter(sG => printShortDate(sG.start) === userDate)
  }
  filterSlotsByGroupId(id: number, slots: Slot[]) {
    console.log('filterSlotsByGroupId', slots)
    console.log('g', this.slotGroups, id)
    const g = this.slotGroups.find(gr => gr.id === id)
    if (!g) { return []}
    return slots.filter(s => g.start <= s.start && s.start < g.end)
  }
  public setMaxMinHour(min: number, max: number) {
    const totalHours = max - min
    this.slotGroups.forEach(sG => {
      const startMinutesInHours = sG.start.getMinutes() / 60
      const endMinutesInHours = sG.end.getMinutes() / 60
      const relStartTime = sG.start.getHours() - min + startMinutesInHours
      const relEndTime = sG.end.getHours() - min + endMinutesInHours
      // top in percentage:
      const top = relStartTime * 100 / totalHours
      const height = (relEndTime - relStartTime) * 100 / totalHours
      sG.top = `${top}%`
      sG.height = `${height}%`
    })
    
  }
}

export default class AppointmentGroupDTO {
  id: number = -1
  name: string = ''
  groupName: string = ''
  groupDescription: string = ''
  props: Prop[] = []
  slots: Slot[] = []
  appointments: Appointment[] = []
  earliestHour: number = -1
  latestHour: number = -1
  dates = new Dates()
  fieldValues: AppointmentProp[]  = []
  public sGM = new slotGroupManager()
  constructor(data: IAppointmentGroup) {
    this.init(data)
  }

  public init(data: IAppointmentGroup) {
    this.id = (data.id !== undefined) ? data.id : -1
    this.name = data.name || ''
    this.sGM.reset()
    if (data.slots) {
      this.slots = data.slots.map(s => new Slot(s))
      let earliestHour = 24
      let latestHour = 0
      this.slots.forEach(s => {
        earliestHour = Math.min(earliestHour, s.start.getHours())
        latestHour = Math.max(latestHour, s.end.getHours())
        this.dates.addDate(s.start)
      })
      this.earliestHour = earliestHour
      this.latestHour = latestHour
      this.slots.forEach(s => {
        s.setMaxMinHour(earliestHour, latestHour)
        s.isInGroup = this.sGM.addGroupBySlot(s)
      })
      this.sGM.setMaxMinHour(earliestHour, latestHour)
    }
    if (data.uGroup) {
      this.id = data.uGroup.id
      this.groupName = data.uGroup.name
      this.groupDescription = data.uGroup.description || ''
      if (data.uGroup.props) {
        this.props = data.uGroup?.props
      }
    }
    if (data.appointments) {
      this.appointments = data.appointments.map(a => {
        return {
          id: a.id,
          start: new Date(a.start.date),
          end: new Date(a.end.date),
          props: a.props
        }
      })
    }
  }

  public filterSlotsByGroupId(groupId: number) {
    console.log('filterSlotsByGroupId', groupId)
    return this.sGM.filterSlotsByGroupId(groupId, this.slots)
  }

  public async getFromServer() {
    const r = await Com('POST:slots', {
      id: -1,
      name: this.name
    })
    console.log('r', r)
    this.init(r as IAppointmentGroup)
  }

  public async getAppointmentsFromServer() {
    const r = await Com('POST:ugroup/appointments', {
      id: this.id
    })
    console.log('r', r)
    this.init(r as IAppointmentGroup)
  }

  public getDays() {
    return this.dates.getDates().map(d => {
      return {
        weekday: d.printWeekday(),
        userDate: d.printUserDate(),
        slots: this.slots.filter(s => s.isAtDate(d.date)).sort((a, b) => a.start < b.start ? -1 : 1)
      }
    })
  }

  public getHours() {
    let hours = []
    let h = this.earliestHour
    let d = new Date()
    let dEnd = new Date()
    while (h < this.latestHour) {
      d.setHours(h, 0, 0, 0)
      dEnd.setHours(h + 1, 0, 0, 0)
      hours.push(new Slot({
        key: 'hourindex' + h,
        free: false,
        start: {date: d.toISOString()},
        end: {date: dEnd.toISOString()},
        min: this.earliestHour,
        max: this.latestHour,
      }))
      h += 1
    }
    return hours
  }

  public getSlotByKey(key: string) {
    return this.slots.find(s => s.key === key)
  }

  public getFields() {
    return this.props.filter(p => p.key1 === 'field').sort((a, b) => parseInt(a.value2, 10) < parseInt(b.value2, 10) ? -1 : 1)
  }

  public async setFieldValue(key: string, value: string) {
    let fV = this.fieldValues.find(f => f.key1 === key)
    if (fV) {
      fV.value1 = value
    } else {
      fV = {
        key1: key,
        value1: value
      }
      this.fieldValues.push(fV)
    }
  }

  public getFieldValue(key: string) {
    return this.fieldValues.find(f => f.key1 === key)?.value1 || ''
  }

  public lintUserInput() {
    let result = {key: '', msg: ''}
    const e = this.getFields().some(f => {
      // get value for field:
      const v = this.getFieldValue(f.key2)
      switch(f.value1) {
        case 'phone':
          if (v.search(/^[+\- 0-9]+$/) === -1) {
            result = {
              key: f.key2,
              msg: `Das Feld ${f.key2} wurde nicht korrekt ausgefüllt`
            }
            return true
          }
          break
        case 'email':
          if (v.search(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/) === -1) {
            result = {
              key: f.key2,
              msg: `Das Feld ${f.key2} ist keine korrekte E-Mail Adresse`
            }
            return true
          }
          break
        default:
          if (v === '') {
            result = {
              key: f.key2,
              msg: `Das Feld ${f.key2} muss ausgefüllt werden`
            }
            return true
          }
      }
    })
    return result
  }

  public hasAppointments() {
    return this.appointments.length > 0
  }

  public getAppointments() {
    return this.appointments
  }

  public async setAppointmentProp(id: number, key: string, value: string) {
    const appointment = this.appointments.find(a => a.id === id)
    if (!appointment) { return }
    let prop = appointment.props.find(p => p.key1 === key)
    if (!prop) {
      prop = {
        key1: key,
        value1: value
      }
      appointment.props.push(prop)
    } else {
      prop.value1 = value
    }
    await Com('PATCH:ugroup/appointment/prop', {
      id: this.id,
      appointmentId: id,
      appointmentPropKey: key,
      appointmentPropValue: value
    })
  }

  public getAppointmentProp(id: number, key: string) {
    const appointment = this.appointments.find(a => a.id === id)
    if (!appointment) { return ''}
    const prop = appointment.props.find(p => p.key1 === key)
    return prop?.value1 || ''
  }

  public async deleteAppointment(id: number) {
    const appointment = this.appointments.find(a => a.id === id)
    if (!appointment) { return ''}
    await Com('DELETE:ugroup/appointment', {
      id: this.id,
      appointmentId: id,
    })
    this.appointments = this.appointments.filter(a => a.id !== id)
  }

  public async sendBooking(key: string) {
    const result = await Com('PUT:slot', {
      id: this.id,
      key: key,
      fieldValues: this.fieldValues
    })
    return result
  }

  public getPropV1(key1: string, key2: string, fb?: string) {
    const prop = this.getProp(-1, key1, key2)
    if (prop) {
      return prop.value1
    }
    return fb || ''
  }

  public getProp(id: number, key1?: string, key2?: string) {
    if (key1 && key2) {
      return this.props.find(f => f.key1 === key1 && f.key2 === key2)
    }
    return this.props.find(f => f.id === id)
  }
}

export class Slot {
  key: string
  free: boolean
  start: Date
  end: Date
  top: string = '0%'
  height: string = '0%'
  heightInt: number = 0
  isInGroup: number = -1
  constructor(data: ISlot) {
    this.key = data.key
    this.free = data.free
    this.start = new Date(data.start.date)
    this.end = new Date(data.end.date)
    if (data.min && data.max) {
      this.setMaxMinHour(data.min, data.max)
    }
  }

  public isAtDate(d: Date) {
    return d.getFullYear() === this.start.getFullYear() && 
    d.getMonth() === this.start.getMonth() &&
    d.getDate() === this.start.getDate()
  }

  public getUserStartTime() {
    return printUserTime(this.start)
  }

  public getUserDate() {
    return printShortDate(this.start)
  }

  public printWeekday() {
    return printWeekday(this.start)
  }

  public printUserLongDateTime() {
    return `${this.getUserStartTime()} am ${this.printWeekday()} den ${this.getUserDate()}`
  }

  public setMaxMinHour(min: number, max: number) {
    const totalHours = max - min
    const startMinutesInHours = this.start.getMinutes() / 60
    const endMinutesInHours = this.end.getMinutes() / 60
    const relStartTime = this.start.getHours() - min + startMinutesInHours
    const relEndTime = this.end.getHours() - min + endMinutesInHours
    // top in percentage:
    const top = relStartTime * 100 / totalHours
    const height = (relEndTime - relStartTime) * 100 / totalHours
    this.top = `${top}%`
    this.height = `${height}%`
    this.heightInt = height
  }
}

class DateDTO {
  key: string
  date: Date
  constructor(d: Date) {
    this.key = `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`
    this.date = new Date(d.getFullYear(), d.getMonth(), d.getDate())
  }

  printWeekday() {
    return printWeekday(this.date)
  }

  printUserDate() {
    return printShortDate(this.date)
  }

}

class Dates {
  list: DateDTO[] = []

  public addDate(d: Date) {
    const key = `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`
    if (!this.list.some(d => d.key === key)) {
      this.list.push(new DateDTO(d))
    }
  }
  public getDates() {
    return this.list.sort((a, b) => a.date < b.date ? -1 : 1)
  }
}

interface ISlot {
  key: string,
  free: boolean,
  start: {date: string},
  end: {date: string},
  min?: number,
  max?: number,
}

interface IAppointment {
  id: number
  start: {date: Date}
  end: {date: Date}
  props: AppointmentProp[]
}

interface IAppointmentGroup {
  id?: number
  name?: string
  slots?: ISlot[]
  appointments?: IAppointment[]
  uGroup?: {
    id: number,
    name: string,
    description?: string,
    props?: Prop[]
  }
}