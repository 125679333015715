import i18n from "./i18n"


function twoDigits(i: number) {
  if (i < 10) {
    return `0${i}`
  }
  return `${i}`
}

export function printUserTime(d: Date) {
  return `${twoDigits(d.getHours())}:${twoDigits(d.getMinutes())}`
}

export function printWeekday(d: Date) {
  const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
  return i18n.get(days[d.getDay()])
}

export function printShortDate(d: Date) {
  return `${twoDigits(d.getDate())}.${twoDigits(d.getMonth() + 1)}.`
}

export function dateFloorHour(d: Date): Date {
  let nD = new Date(d)
  nD.setMinutes(0)
  nD.setSeconds(0)
  nD.setMilliseconds(0)
  return nD
}

export function dateCeilHour(d: Date): Date {
  let nD = dateFloorHour(d)
  nD.setHours(nD.getHours() + 1)
  return nD
}
