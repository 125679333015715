import Com from "../services/ComService";
import AppointmentRuleDTO, { IAppointmentRule } from "./AppointmentRuleDTO";

export default class AppointmentRulesDTO {
  rules: AppointmentRuleDTO[] = []
  UGroupId: number = -1
  constructor(o: IAppointmentRules) {
    this.UGroupId = o.UGroupId
  }
  async getFromServer() {
    const r = await Com('POST:ugroup/appointmentrules', {
      UGroupId: this.UGroupId
    }) as {rules: IAppointmentRule[]}
    this.rules = r.rules.map(rule => new AppointmentRuleDTO(rule))
  }
  
}

export interface IAppointmentRules {
  UGroupId: number
}