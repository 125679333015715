import Com from '../services/ComService'
import FileDTO, { IFile } from './FileDTO'

export enum PreferenceType {
  boolean,
  text,
  date,
  file,
  number,
}

type Preference = {
  id?: number,
  name: string,
  key1: string,
  key2: string,
  values: PreferenceValue[],
  kind: string, // Which part of preferences is this in?
  type: PreferenceType, // WHich type of value?
  standard: string,
  description?: string,
}

export type PreferenceValue = {
  id?: number,
  value1: string,
  value2: string,
  start: Date | null,
  end: Date | null,
  file: FileDTO | null,
}

let prefs: Preference[] = [
  {
    name: 'Dings aktiviert',
    key1: 'thing',
    key2: 'enable',
    values: [],
    kind: 'default',
    type: PreferenceType.boolean,
    standard: '0'
  },
  {
    name: 'Datenschutz Disclaimer',
    key1: 'content',
    key2: 'datenschutz',
    values: [],
    kind: 'default',
    type: PreferenceType.text,
    standard: 'Wir benutzen keine Cookies! Wir speichern nur Daten, die sie in die dafür vorgesehenen Eingabefelder eingeben. Ein Mitarbeiter kann sämtliche Daten zur Bearbeitung einsehen. Ihre Daten werden nur so lange gespeichert, wie es technisch nötig ist. Nach verstreichen des Termines werden Ihre eingaben innerhalb von 14 Tagen gelöscht.'
  },
  {
    name: 'Impressum',
    key1: 'content',
    key2: 'impressum',
    values: [],
    kind: 'default',
    type: PreferenceType.text,
    standard: 'Verantwortlich für die Inhalte ist:'
  }
]

export class PreferenceDTO {
  id: number = -1
  name: string
  key1: string
  key2: string
  values: PreferenceValue[]
  kind: string
  type: PreferenceType
  standard: any
  description: string
  constructor(data: Preference) {
    this.id = data.id || -1
    this.name = data.name
    this.key1 = data.key1
    this.key2 = data.key2
    this.values = []
    this.kind = data.kind
    this.type = data.type
    this.standard = data.standard
    this.description = data.description || ''
  }

  public initData(data: IPreference) {
    // this.key1 = data.key1
    // this.key2 = data.key2
    try {
      this.id = data.id || -1
    } catch(err) {
      throw('not logged in?')
    }
    this.values = data.values.map(v => {
      let newV = {
        id: v.id,
        value1: v.value1,
        value2: v.value2,
        start: v.start ? new Date(v.start.date) : null,
        end: v.end ? new Date(v.end.date) : null,
        file: null,
      }
      return newV
    })
    this.kind = data.kind
  }
  
  /*
  public async getFromServer() {
    
  }
  */

  public async saveValue(valueId: number) {
    const value = this.values.find(v => v.id === valueId)
    if (!value) {
      return
    }
    const data = await Com('SET:admin/preference', {
      id: value.id,
      fileId: value.file?.id || -1,
      key1: this.key1,
      key2: this.key2,
      value1: value.value1,
      value2: value.value2,
      start: value.start,
      end: value.end,
      kind: this.kind,
      type: this.type,
    }) as IPreference
    if (valueId === -1) {
      const newData = await Com('admin/preference', {
        id: this.id
      }) as IPreference
      this.initData(newData)
    } else {
      this.initData(data)
    }
    
  }

  public async saveDate(valueId: number, d: Date | null, t: 'start' | 'end') {
    const value = this.values.find(v => v.id === valueId)
    if (!value) {
      return
    }
    if (t === 'start') {
      value.start = d
      if (value.start && value.end && value.start > value.end) {
        value.end = value.start
      }
    } else {
      value.end = d
      if (value.start && value.end && value.start > value.end) {
        value.start = value.end
      }
    }
    await this.saveValue(valueId)
  }

  public async removeValue(valueId: number) {
    this.values = this.values.filter(v => v.id !== valueId)
    const data = await Com('DELETE:admin/preference/value', {
      preferenceId: this.id,
      valueId: valueId
    }) as IPreference
    this.initData(data)
  }

  public async setValue(valueId: number, valueValue: string) {
    let value = this.values.find(v => v.id === valueId) || {
      id: valueId,
      value1: '',
      value2: '',
      start: null,
      end: null,
      file: null,
    }
    const result = await Com('Set:admin/preference', {
      id: (value.start === null && value.end === null) ? -1 : value.id,
      fileId: value.file?.id || -1,
      key1: this.key1,
      key2: this.key2,
      value1: valueValue,
      value2: value.value2,
      start: value.start,
      end: value.end,
      kind: this.kind,
      type: this.type,
    }) as IPreference
    this.initData(result)
    return valueValue
  }

  public getValues(): PreferenceValue[] {
    return this.values.filter(v => !(v.start === null && v.end === null))
  }

  public getDefaultValue(): PreferenceValue {
    return this.values.find(v => v.start === null && v.end === null) || {
      id: -1,
      value1: this.standard,
      value2: '',
      start: null,
      end: null,
      file: null,
    }
  }

  public getCurrentValue(): string {
    const vsRaw = this.getValues() as PreferenceValue[]
    // console.log('ok', this.getDefaultValue())
    if (!vsRaw) { return this.getDefaultValue()?.value1 }
    const vs = vsRaw.sort((a, b) => {
      const A = -1
      const B = 1
      if (a.start === null) { return A }
      if (b.start === null) { return B }
      if (a.start < b.start) { return A}
      if (a.start > b.start) { return B}
      return 0
    })
    const now = new Date()
    const g = vs.find((v) => {
      return v.end === null || v.end < now
    })
    return (g || this.getDefaultValue())?.value1 || ''
  }

  public initNewValue() {
    this.values.push(
      {
        id: -1,
        value1: '',
        value2: '',
        start: new Date(),
        end: new Date(),
        file: null,
      }
    )
  }
}

export class PreferencesDTO {

  prefs: PreferenceDTO[] = []
  initDone: boolean = false

  constructor() {
    this.prefs = prefs.map(p => new PreferenceDTO(p))
    // this.getFromServer()
  }

  public async init() {
    if (!this.initDone) {
      await this.getFromServer()
    }
  }

  private initData(data: IPreference[]) {
    try {
      data.forEach(d => {
        const l = this.getPref(d.key1, d.key2)
        if (!l) { return }
        l.initData(d)
      })
    } catch(err) {
      throw('not logged in?')
    }
    this.initDone = true
  }

  public getPref(key1: string, key2: string) {
    return this.prefs.find(l => l.key1 === key1 && l.key2 === key2)
  }

  public getPrefV1(key1: string, key2: string): string {
    const p = this.getPref(key1, key2)
    if (!p) { return '' }
    console.log('getpref1', key1, key2, p)
    return p.getCurrentValue()
  }

  private async getFromServer() {
    const data = await Com('preferences') as IPreference[]
    this.initData(data)
  }

  public list() {
    return this.prefs
  }
}

export interface IPreferenceValue {
  id: number,
  value1: string,
  value2: string,
  start: {date: string} | null,
  end: {date: string} | null,
  file: IFile | null,
}

export interface IPreference {
  id: number,
  key1: string,
  key2: string,
  values: IPreferenceValue[],
  kind: string,
}

const PreferenceService = new PreferencesDTO()
export default PreferenceService
