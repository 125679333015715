import { useEffect, useState } from "react"
import { ArrowLeftCircle, Printer } from "react-feather"
import AppointmentGroupDTO from "../../dto/AppointmentGroupDTO"
import { printUserTime, printWeekday } from "../../services/DateService"
import Input from "../Input/Input"
import Spinner from "../Spinner/Spinner"
import './MakeAppointment.scss'
enum Status {
  init,
  loading,
  done,
  success,
}
export default function MakeAppointment (prop: {}) {
  const nameRaw = window.location.href.match(/[^/]+$/)
  const name = (nameRaw) ? nameRaw[0] : ''
  const [appointmentGroup] = useState(new AppointmentGroupDTO({name: name}))
  const [status, setStatus] = useState(Status.loading)
  const [selectedSlot, setSelectedSlot] = useState('')
  useEffect(() => {
    appointmentGroup.getFromServer().then(() => {
      setStatus(Status.done)
    })
  }, [])
  if (status !== Status.loading && appointmentGroup.id === -1) {
    return <div>Diese Seite ist ungültig!</div>
  }
  return <div>
    {
      status === Status.loading &&
      <Spinner />
    }
    {
      selectedSlot === '' && status === Status.done &&
      <PrintTimeTable
        appointmentGroup={appointmentGroup}
        setSelectedSlot={(name) => setSelectedSlot(name)}
      />
    }
    {
      selectedSlot !== '' && status === Status.done &&
      <PrintBookSlot
        appointmentGroup={appointmentGroup}
        selectedSlot={selectedSlot}
        setSelectedSlot={(name) => setSelectedSlot(name)}
        setStatus={(status: Status) => setStatus(status)}
      />
    }
    {
      status === Status.success &&
      <PrintSuccessPage
        appointmentGroup={appointmentGroup}
        selectedSlot={selectedSlot}
      />
    }
  </div>
}

function ZoomedTable(props: {
  appointmentGroup: AppointmentGroupDTO,
  setSelectedSlot: (name: string) => void,
  unzoom: () => void,
  selectedGroupId: number,
}) {
  const slots = props.appointmentGroup.filterSlotsByGroupId(props.selectedGroupId)
  return <div className='w3-container'>
    <div className='' dangerouslySetInnerHTML={{__html: props.appointmentGroup.getPropV1('content', 'aboveCalendar')}}></div>
    <button
      className='w3-button w3-yellow'
      onClick={props.unzoom}
    >&times; Zurück zur Übersicht</button>
    {
      slots.length === 0 &&
      <div>Leider sind hier keine Termine verfügbar</div>
    }
    {
      slots.map(s => {
        return <div
          key={s.start.getTime()}
          className='w3-container w3-margin-bottom w3-margin-top w3-padding w3-row zoom-slot'
        >
          <div className='w3-col m3 s5'>
            {printWeekday(s.start)}
          </div>
          <div className='w3-col m2 s7'>
            {s.getUserDate()}
          </div>
          <div className='w3-col m3 s5'>
            <b>{s.getUserStartTime()} Uhr</b>
          </div>
          <div className='w3-col m4 s7 w3-right-align'>
            <button
              className='w3-button w3-green'
              onClick={() => {
                props.setSelectedSlot(s.key)
              }}
            >Termin Buchen</button>
          </div>
        </div>
      })
    }
    <div className='' dangerouslySetInnerHTML={{__html: props.appointmentGroup.getPropV1('content', 'belowCalendar')}}></div>
  </div>
}

function PrintTimeTable(props: {
  appointmentGroup: AppointmentGroupDTO,
  setSelectedSlot: (name: string) => void,
}) {
  const appointmentGroup = props.appointmentGroup
  const [selectedGroupId, setSelectedGroupId] = useState(-1)
  if (selectedGroupId > -1) {
    return <ZoomedTable
      appointmentGroup={props.appointmentGroup}
      setSelectedSlot={props.setSelectedSlot}
      unzoom={() => {
        setSelectedGroupId(-1)
      }}
      selectedGroupId={selectedGroupId}
    ></ZoomedTable>
  }
  return <div>
    
    <div className='w3-container' dangerouslySetInnerHTML={{__html: appointmentGroup.getPropV1('content', 'aboveCalendar')}}></div>
    <div className='w3-row overflow-scroll w3-container'>

      <div
        className='w3-cell first-col'
      >
        <div>Zeit&nbsp;</div>
        <div>&nbsp;</div>
        <div className='slots'>
            {
              appointmentGroup.getHours().map(s => <div
                key={s.key}
                className='timeCol'
                style={{
                  top: s.top,
                  height: s.height
                }}
              >
                <div>
                  {s.getUserStartTime()}
                </div>
              </div>)
            }
          </div>
      </div>
      {
        appointmentGroup.getDays().map(d => <div
          key={d.userDate}
          className='w3-cell'
        >
          <div>
            {d.weekday}
          </div>
          <div>
            {d.userDate}
          </div>
          <div className='slots'>
            {
              d.slots.map(s => {
                if (s.isInGroup > -1) { return null }
                return <div
                  key={s.key}
                  className='timeslot'
                  style={{
                    top: s.top,
                    height: s.height
                  }}
                  onClick={() => {
                    props.setSelectedSlot(s.key)
                  }}
                >
                  <div>
                    {s.getUserStartTime()}
                  </div>
                </div>
              })
            }
            {
              appointmentGroup.sGM.getGroups(d.userDate).map(s => {
                return <div
                  key={s.id + '-group'}
                  className='timeslot'
                  style={{
                    top: s.top,
                    height: s.height
                  }}
                  onClick={() => {
                    setSelectedGroupId(s.id)
                  }}
                  title={`${s.count} Termine verfügbar`}
                >
                  <span>
                    {printUserTime(s.start)}
                  </span>
                  <div className='groupCount'>
                    {s.count}
                  </div>
                </div>
              })
            }
          </div>
        </div>)
      }
    </div>
    <div className='w3-container' dangerouslySetInnerHTML={{__html: appointmentGroup.getPropV1('content', 'belowCalendar')}}></div>
  </div>
}

function PrintBookSlot(props: {
  selectedSlot: string,
  appointmentGroup: AppointmentGroupDTO,
  setSelectedSlot: (name: string) => void,
  setStatus: (status: Status) => void,
}) {
  const g = props.appointmentGroup
  const slot = g.getSlotByKey(props.selectedSlot)
  const [mark, setMark] = useState('')
  const [eMsg, seteMsg] = useState('')
  const [status, setStatus] = useState(Status.done)
  if (status === Status.loading) {
    return <div className='w3-container'>
      <Spinner />
    </div>
  }
  return <div className='w3-container'>
    <div className='' dangerouslySetInnerHTML={{__html: props.appointmentGroup.getPropV1('content', 'aboveBooking')}}></div>
    <button
      className='w3-btn w3-button w3-yellow'
      onClick={() => props.setSelectedSlot('')}
    ><span>〈 Anderen Termin wählen</span></button>
    {
      !slot &&
      <div>Fehler - Termin nicht verfügbar</div>
    }
    <h3>{slot?.printUserLongDateTime()}</h3>
    <div>
      {g.getFields().map(f => <div
        key={`${f.key1}-${f.key2}`}
        className={`w3-container-fluid w3-margin-bottom ${mark === f.key2 ? 'w3-text-red w3-border w3-border-red w3-border-round' : ''}`}
      >
        <label>{f.key2}</label>
        <Input
          className={`w3-input`}
          value={g.getFieldValue(f.key2)}
          cb={(value) => {
            if (f.value1 === 'phone' || f.value1 === 'email') {
              value = value.replace(/[ ]/g, '')
            }
            g.setFieldValue(f.key2, value)
          }}
        />
      </div>)}
    </div>
    {
      eMsg &&
      <div className='alert-box'>{eMsg}</div>
    }
    <div>
      <button
        className='w3-button w3-green'
        onClick={async () => {
          const result = g.lintUserInput()
          console.log('result', result)
          setMark(result.key)
          seteMsg(result.msg)
          if (result.key === '') {
            setStatus(Status.loading)
            const r = await g.sendBooking(props.selectedSlot)
            props.setStatus(Status.success)
          }
        }}
      >Termin Buchen</button>
    </div>
    <div className='' dangerouslySetInnerHTML={{__html: props.appointmentGroup.getPropV1('content', 'belowBooking')}}></div>
  </div>
}

function PrintSuccessPage(props: {
  selectedSlot: string,
  appointmentGroup: AppointmentGroupDTO,
}) {
  const g = props.appointmentGroup
  const slot = g.getSlotByKey(props.selectedSlot)
  return <div className='w3-container'>
    <div className='bookingSuccess'>
      <div className='w3-container' dangerouslySetInnerHTML={{__html: g.getPropV1('content', 'successBooking')}}></div>
      <div
        className={`w3-container-fluid w3-row w3-padding w3-margin w3-round`}
      >
        <div className='w3-col m3 s6'>Termin</div>
        <div className='w3-col m9 s6'>{slot?.printUserLongDateTime()}</div>
      </div>
      <div>
        {g.getFields().map(f => <div
          key={`${f.key1}-${f.key2}`}
          className={`w3-container-fluid w3-row w3-padding w3-margin w3-round`}
        >
          <div className='w3-col m3 s6'>{f.key2}</div>
          <div className='w3-col m9 s6'>{g.getFieldValue(f.key2)}</div>
        </div>)}
      </div>
      <div className='w3-display-container'>
        <button
          className='w3-button w3-green printer w3-display-middle dontPrint'
          onClick={() => {
            window.print()
          }}
        ><Printer /></button>
      </div>
    </div>
  </div>
}