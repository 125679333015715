import { NavLink } from "react-router-dom"
import I18n from "../I18n"
import Logout from "../Login/Logout"
import './Menu.scss'

const listClass = 'menu-list'
const listItemClass = 'menu-list-item'
const itemClass = 'menu-list-item-button w3-button w3-ripple'

type Item = {
  key: string,
  target: string
}

type Props = {
  config: {
    items: Item[]
  }
}

const RenderItem = (props: {item: Item, index: number}) => {
  if (props.item.target === 'logout') {
    return <li key={`${props.index}`}>
      <Logout />
    </li>
  }
  return <li
      key={`${props.index}`}
      className={listItemClass}
    >
      <NavLink
        to={props.item.target}
        className={({isActive}) => isActive ? itemClass + ' menu-item-active' : itemClass + ' menu-item-inactive'}
      >
        <I18n value={props.item.key} />
      </NavLink>
    </li>
}

const Menu = (props: Props) => {
  const items = props.config.items
  return <ul
    className={listClass}
  >
    {
      items.map((item, index) => RenderItem({item: item, index: index}))
    }
  </ul>
}

export default Menu
