import Com from "../services/ComService"

export const weekdays = [
  {
    id: 1,
    name: 'monday'
  },
  {
    id: 2,
    name: 'tuesday'
  },
  {
    id: 3,
    name: 'wednesday'
  },
  {
    id: 4,
    name: 'thursday'
  },
  {
    id: 5,
    name: 'friday'
  },
  {
    id: 6,
    name: 'saturday'
  },
  {
    id: 0,
    name: 'sunday'
  },
]
export default class AppointmentRuleDTO {
  id: number = -1
  UGroupID: number = -1
  start: Date | null = new Date()
  end: Date | null = new Date()
  startTime: string = ''
  endTime: string = ''
  activatedWeekdays: string[] = []
  eventLength: number = 5
  constructor(o: IAppointmentRule) {
    this.init(o)
  }
  init(o: IAppointmentRule) {
    this.id = (o.id !== undefined) ? o.id : -1
    this.UGroupID = (o.UGroupId !== undefined) ? o.UGroupId : -1
    this.activatedWeekdays = o.weekdays?.split(',') || []
    this.eventLength = o.eventLength || 5
    this.start = (o.start) ? new Date(o.start.date) : new Date()
    this.end = (o.end) ? new Date(o.end.date) : null
    this.startTime = o.startTime || ''
    this.endTime = o.endTime || ''
  }
  setStart(d: Date | null) {
    if (!d) { return undefined }
    this.start = new Date(d.setHours(0, 0, 0, 0))
  }
  setEnd(d: Date | null) {
    if (d === null) {
      this.end = null
      return
    }
    if (!d) { return undefined }
    this.end = new Date(d.setHours(0, 0, 0, 0))
  }
  getWeekdays() {
    return weekdays.map(w => {
      return {
        id: w.id,
        name: w.name,
        active: this.activatedWeekdays.indexOf(w.name) > -1
      }
    })
  }
  setDay(d: string) {
    this.activatedWeekdays = this.activatedWeekdays.filter(a => a != d).concat([d])
  }
  unsetDay(d: string) {
    this.activatedWeekdays = this.activatedWeekdays.filter(a => a != d)
  }
  public equalizeTime(t: string) {
    const shortScope = /([0-9][0-9]|[0-9])/
    const scope = /([0-9][0-9]|[0-9])[^0-9]*([0-9][0-9])/
    const mf = t.match(scope)
    const ms = t.match(shortScope)
    if (mf) {
      return `${('0' + mf[1]).slice(-2)}:${mf[2]}`
    } else if (ms) {
      return `${('0' + ms[1]).slice(-2)}:00`
    }
    return t
  }
  setStartTime(t: string) {
    this.startTime = this.equalizeTime(t)
    console.log('setStartTime', t, this.startTime)
  }
  setEndTime(t: string) {
    this.endTime = this.equalizeTime(t)
    console.log('setEndTime', t, this.endTime)
  }
  getStartTime() {
    console.log('this.startTime', this.startTime)
    return this.startTime
  }
  getEndTime() {
    console.log('this.endTime', this.endTime)
    return this.endTime
  }

  setInterval(v: string) {
    this.eventLength = parseInt(v, 10)
  }

  getInterval() {
    return `${this.eventLength}`
  }

  getHumanDate(d: Date) {
    return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`
  }

  getHumanStart() {
    if (this.start) {
      return this.getHumanDate(this.start)
    }
    return '-'
  }

  getHumanEnd() {
    if (this.end) {
      return this.getHumanDate(this.end)
    }
    return '-'
  }

  public async saveToServer(): Promise <string> {
    const r = await Com('PUT:ugroup/appointmentrule', {
      id: this.id,
      UGroupId: this.UGroupID,
      start: this.start,
      end: this.end,
      count: -1,
      weekdays: this.activatedWeekdays.join(','),
      startTime: this.startTime,
      endTime: this.endTime,
      eventLength: this.eventLength,
      parallelEvents: 1,
    }, 1) as {status: string}
    console.log('r:::', r)
    return r.status
  }

  public async delete(): Promise <boolean> {
    const r = await Com('DELETE:ugroup/appointmentrule', {
      id: this.id,
      UGroupId: this.UGroupID
    }, 1) as {status: string}
    return r.status === 'okay'
  }
}

type ServerDate = {
  date: string,
  timezone: string,
  timezone_type: number,
}

export interface IAppointmentRule {
  id?: number
  UGroupId: number
  weekdays?: string
  start?: ServerDate
  end?: ServerDate
  startTime?: string
  endTime?: string
  eventLength?: number
}