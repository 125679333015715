// import React, { useEffect, useState } from 'react'
import { Routes, Route, Link, NavLink } from 'react-router-dom'
import About from './components/Page/About'
import Layout from './components/Page/Layout'
// import LoggedOut from './components/Page/LoggedOut'
// import { LoginGuard } from './services/LoginService'
import Landing from './components/Page/Landing'
import AdminLayout from './components/Admin/AdminLayout'
import Root from './components/Page/Root'
import AdminLanding from './components/Admin/AdminLanding'
import UserPreferences from './components/User/Preferences'
import UserLayout from './components/User/UserLayout'
import UserList from './components/Admin/UserList'
import GroupList from './components/Admin/GroupList'
import SetPasswordByToken from './components/User/SetPasswordByToken'
import Preferences from './components/Preference/Preferences'
import MakeAppointment from './components/Page/MakeAppointment'
import Impressum from './components/Page/Impressum'
import Datenschutz from './components/Page/Datenschutz'
import ComplienceHeader from './components/ComplienceHeader'
// import broadcastService from './services/BroadcastService'



function App() {
  // const [loginStatus, setLoginStatus] = useState('')
  /*
  useEffect(() => {
    broadcastService.subscribe('appComponent', (key: string, r) => {
      if (key.search(/login|logout/) > -1) {
        setLoginStatus(r)
      }
    })
  })
  /*
  if (LoginGuard()) {
    return <LoggedOut></LoggedOut>
  }
  */
  return (
    <div className='App'>
      <ComplienceHeader />
      <Routes>
        <Route path="/" element={<Root />}>
          <Route path="user" element={<UserLayout />}>
            <Route index element={<UserPreferences />} />
            <Route path="groupList" element={<GroupList />} />
          </Route>
          <Route path="impressum" element={<Impressum />} />
          <Route path="datenschutz" element={<Datenschutz />} />
          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<AdminLanding />} />
            <Route path="groupList" element={<GroupList />} />
            <Route path="preferences" element={<Preferences />} />
            <Route path="userList" element={<UserList />} />
          </Route>
          <Route path="page/:name" element={<MakeAppointment />} />
          <Route path="*" element={<Layout />}>
            <Route path="setPassword/:hash" element={<SetPasswordByToken />} />
            <Route path="login" element={<Landing />} />
            <Route index element={<About />}/>
            <Route path="about" element={<About />} />
          </Route>
        </Route>
      </Routes>
    </div> 
  )
}

export default App;
