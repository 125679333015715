import { Outlet } from "react-router-dom"

const About = () => {
  return <div className='w3-container'>
    <h1>Willkommen beim Buchungstool</h1>
    <p>Dies ist ein Buchungstool, mit dem ein Kunde beim Anbieter einen Termin buchen kann um z.B. vor Ort vorbei zu kommen.</p>
    <p>Daten-Sparsamkeit ist hier das wichtigste Ziel:</p>
    <p>Es werden vom Kunden keinerlei Daten gespeichert, bis er explizit dazu aufgefordert wird.</p>
    <p>Eingegebene Daten werden ausschließlich zur Bearbeitung des Termines verwendet und sind für authorisierte Mitarbeiter auf Anbieter Seite sichtbar. Sie werden ein paar Tage nach Ablauf des Termines unwiederbringlich gelöscht.</p>
    <p>Es werden keinerlei Cookies oder sonstiges von uns gesetzt. Es entstehen ausschließlich technisch notwendige Daten, die für den Betrieb von Webseiten gebraucht werden.</p>
    <h2>Warum sehe ich diese Seite?</h2>
    <p>Der Anbieter wird ein Buchungstool unter einer URL zur Verfügung stellen, wenn er Buchungen anbieten will. Diese Seite ist daher, wenn sie diese URL nicht kennen leider nutzlos.</p>
    <Outlet></Outlet>
  </div>
}

export default About
