import { useEffect, useState } from "react"
import { Trash } from "react-feather"
import AppointmentGroupDTO from "../../dto/AppointmentGroupDTO"
import {printShortDate, printWeekday, printUserTime} from '../../services/DateService'
import Input, { OkInput } from "../Input/Input"

const adminTextFields = [
  {
    name: 'c1',
    key: '__c1',
  },
  {
    name: 'c2',
    key: '__c2',
  },
  {
    name: 'c3',
    key: '__c3',
  }
]

const adminButtonFields = [
  {
    name: 'b1',
    key: '__b1',
  },
  {
    name: 'b2',
    key: '__b2',
  },
  {
    name: 'b3',
    key: '__b3',
  },
  {
    name: <Trash />,
    className: 'w3-red',
    key: 'trash'
  }
]
export default function ShowAppointments(props: {
  UGroupId: number
}) {
  const [appointmentGroup] = useState(new AppointmentGroupDTO({id: props.UGroupId}))
  const [Rerender, setRerender] = useState(0)
  useEffect(() => {
    appointmentGroup.getAppointmentsFromServer().then(() => {
      setRerender(Rerender + 1)
    })
  }, [])
  return <div>
    <h2>Buchungen</h2>
    {
      !appointmentGroup.hasAppointments() &&
      <div>Keine Buchungen vorhanden</div>
    }
    {
      appointmentGroup.getAppointments().map(a => {
      return <div
          key={`${a.id}`}
        >
          <div className='w3-row w3-card w3-margin w3-padding'>
            <div className='w3-col m2'>
              <div>
                {printWeekday(a.start)} {printShortDate(a.start)}
              </div>
              <div>
                {printUserTime(a.start)} - {printUserTime(a.end)}
              </div>
            </div>
            <div className='w3-col m5'>
              {
                appointmentGroup.getFields().map(f => {
                  const value1 = a.props.find(p => p.key1 === f.key2)?.value1
                  const value2 = f.value1
                  return <div
                    key={`${f.key1}-${f.key2}`}
                    className='w3-padding'
                  >
                    <div>{f.key2}</div>
                    {
                      value2 !== 'email' &&
                      <div className='w3-bold'>{value1}</div>
                    }
                    {
                      value2 === 'email' &&
                      <div><a href={`mailto:${value1}`}>{value1}</a></div>
                    }
                  </div>
                })
              }
            </div>
            <div className='w3-col m3 w3-padding'>
              {
                adminTextFields.map(atf => {
                  const cb = async function (value: string) {
                    await appointmentGroup.setAppointmentProp(a.id, atf.key, value)
                    setRerender(Rerender + 1)
                  }
                  return <div key={atf.key}>
                    <label>{atf.name}</label>
                    <OkInput
                      className='w3-input w3-border'
                      value={appointmentGroup.getAppointmentProp(a.id, atf.key)}
                      onEnter={cb}
                      cb={cb}
                    />
                  </div>
                })
              }
            </div>
            <div className='w3-col m2 w3-center'>
              {
                adminButtonFields.map(abf => {
                  const buttonValue = appointmentGroup.getAppointmentProp(a.id, abf.key)
                  return <div key={abf.key}>
                    <button
                      className={`${abf.className || ''} w3-margin w3-btn w3-border ${buttonValue === 'active' ? 'w3-green' : '' }`}
                      onClick={async () => {
                        if (abf.key === 'trash') {
                          if (window.confirm('Buchung wirklich löschen?')) {
                            await appointmentGroup.deleteAppointment(a.id)
                            setRerender(Rerender + 1)
                          }
                          return
                        }
                        const newValue = (buttonValue === 'active') ? 'inactive' : 'active' 
                        await appointmentGroup.setAppointmentProp(a.id, abf.key, newValue)
                        setRerender(Rerender + 1)
                      }}
                    >{abf.name}</button>
                  </div>
                })
              }
            </div>
          </div>
        </div>
      })
    }
  </div>
}
