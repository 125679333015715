import { useEffect, useState } from "react"
import { PreferencesDTO } from "../dto/PreferenceDTO"
import './ComplienceHeader.scss'

enum Status {
  init,
  loading,
  done,
  success,
  datenschutz,
  impressum
}

export default function ComplienceHeader() {
  // const nameRaw = window.location.href.match(/[^/]+$/)
  // const name = (nameRaw) ? nameRaw[0] : ''
  const [prefs] = useState(new PreferencesDTO())
  const [status, setStatus] = useState(Status.loading)
  useEffect(() => {
    prefs.init().then(() => {
      console.log('prefs loaded')
      setStatus(Status.done)
    })
  }, [])

  function renderPage() {
    if (status === Status.datenschutz || status === Status.impressum) {
      const contentName = (status === Status.impressum) ? 'impressum' : 'datenschutz'
      return <div className='complienceHeaderPopup w3-container '>
        <button
          className='w3-button'
          onClick={() => {
            setStatus(Status.done)
          }}
        >&times; Schließen</button>
        <div dangerouslySetInnerHTML={{__html: prefs.getPrefV1('content', contentName)}}></div>
      </div>
    }
    return null
  }
  return <div className='w3-container page-header'>
    <button
      className='w3-button'
      onClick={() => {
      setStatus(Status.impressum)
    }}>Impressum</button>
    <button
      className='w3-button'
      onClick={() => {
      setStatus(Status.datenschutz)
    }}>Datenschutz</button>
    {
      renderPage()
    }
  </div>
}