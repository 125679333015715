import AppointmentRuleDTO, { weekdays } from "../../dto/AppointmentRuleDTO";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import i18n from "../../services/i18n";
import { Edit, ToggleLeft, ToggleRight, Trash } from "react-feather";
import { useState } from "react";
import Input from "../Input/Input";

enum Status {
  loading,
  edit,
  saving,
  done
}

export default function AppointmentRule(props: {
  AppointmentRule: AppointmentRuleDTO,
  addMode?: boolean,
  cb?: (appointmentRule: AppointmentRuleDTO) => void
}) {
  const rule = props.AppointmentRule
  const [s, setS] = useState(props.addMode ? Status.edit : Status.done)
  const [Rerender, setRerender] = useState(0)
  if (s === Status.done) {
    const tbody = [
      {
        title: i18n.get('validDates'),
        cols: [
          {
            title: i18n.get('startDate'),
            content: rule.getHumanStart()
          },
          {
            title: i18n.get('endDate'),
            content: rule.getHumanEnd()
          }
        ]
      },
      {
        title: i18n.get('timeRange'),
        cols: [
          {
            title: i18n.get('dailyStartTime'),
            content: rule.startTime
          },
          {
            title: i18n.get('dailyEndTime'),
            content: rule.endTime
          }
        ]
      },
      {
        title: 'Details',
        cols: [
          {
            title: i18n.get('interval'),
            content: `${rule.eventLength} min`
          },
          {
            title: i18n.get('weekdays'),
            //content: `${rule.activatedWeekdays.join(', ')}`
            content: weekdays.map(wd => <div
              key={wd.name}
              className={`w3-show-inline-block w3-padding ${rule.activatedWeekdays.indexOf(wd.name) > -1 ? 'w3-green' : 'w3-grey'}`}
            >
              {i18n.get(wd.name)[0]}
            </div>)
          }
        ]
      }
    ]
    return <div
      className='w3-container w3-padding w3-border w3-border-grey'
    >
      {
        tbody.map((r, i) => <div
          key={`r-${i}`}
          className='w3-row'
        >
          <div className='w3-col m2'>
            {r.title}
          </div>
          {
            r.cols.map((c, j) => <div
              key={`c-${j}`}
              className='w3-col m5'
            >
              <div>{c.title}</div>
              <div>{c.content}</div>
            </div>)
          }
        </div>)
      }
      <button
        className='w3-yellow w3-button w3-ripple'
        onClick={() => setS(Status.edit)}
      ><Edit /></button>
      <button
        className='w3-red w3-button w3-ripple'
        onClick={async () => {
          if (window.confirm(i18n.get('deleteRuleOkay'))) {
            await rule.delete()
            if (props.cb) { props.cb(rule) }
          }
        }}
      ><Trash /></button>
    </div>
  }
  return <div
    className='w3-container w3-padding'
  >
    <div className='w3-row'>
      <div className='w3-col s12 m6'>
        {i18n.get('startDate')}
        <DatePicker
          selected={rule.start}
          onChange={(d) => {
            rule.setStart(d)
            setRerender(Rerender + 1)
          }}
        />
        {i18n.get('endDate')}
        <DatePicker
          selected={rule.end}
          onChange={(d) => {
            rule.setEnd(d)
            setRerender(Rerender + 1)
          }}
        />
        <button
          title={i18n.get('removeEndDate')}
          onClick={() => {
            rule.setEnd(null)
            setRerender(Rerender + 1)
          }}
        >&times;</button>
      </div>
      <div className='w3-col s12 m6'>
        <div>
          {i18n.get('dailyStartTime')}
        </div>
        <Input
          cb={(v) => {
            rule.setStartTime(v)
            // setRerender(Rerender + 1)
          }}
          onEnter={(v) => {
            rule.setStartTime(v)
            setRerender(Rerender + 1)
          }}
          validate={(v: string) => rule.equalizeTime(v)}
          value={rule.getStartTime()}
        />
        <div>
          {i18n.get('dailyEndTime')}
        </div>
        <Input
          className={`iteration-${Rerender}`}
          cb={(v) => {
            rule.setEndTime(v)
            // setRerender(Rerender + 1)
          }}
          onEnter={(v) => {
            rule.setEndTime(v)
            setRerender(Rerender + 1)
          }}
          validate={(v: string) => rule.equalizeTime(v)}
          value={rule.getEndTime()}
        />
      </div>
      <div className='w3-col s12 m6'>
        <div>
          {i18n.get('interval')}
        </div>
        <Input
          cb={(v) => {
            rule.setInterval(v)
            // setRerender(Rerender + 1)
          }}
          onEnter={(v) => {
            rule.setInterval(v)
            setRerender(Rerender + 1)
          }}
          value={rule.getInterval()}
        />
      </div>
      <div className='w3-col m6'>
        {
          rule.getWeekdays().map(w => <div
            key={w.name}
          >
            {
              !w.active &&
              <button
                className='w3-button w3-ripple'
                onClick={() => {
                  rule.setDay(w.name)
                  setRerender(Rerender + 1)
                  console.log('rrr', rule.activatedWeekdays)
                }}
              ><ToggleLeft
                color='red'
              /></button>
            }
            {
              w.active &&
              <button
                className='w3-button w3-ripple'
                onClick={() => {
                  rule.unsetDay(w.name)
                  setRerender(Rerender + 1)
                  console.log('rrr', rule.activatedWeekdays)
                }}
              ><ToggleRight color='green' /></button>
            }
            {i18n.get(w.name)}
          </div>)
        }
      </div>
    </div>
    <button
      className='w3-button w3-ripple w3-green'
      onClick={async () => {
        console.log('save rule')
        const r = await rule.saveToServer()
        if (r === 'okay') {
          if (props.cb) {
            return props.cb(rule)
          }
          return
        }
      }}
    >{i18n.get('save')}</button>
  </div>
}